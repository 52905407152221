const router = async (el) => {
  let rout = 'base.js'
  const page = el || document.querySelector('.content-inner')
  // Dynamically Load Modules
  switch(true) {

      // Home Page
      case page.classList.contains('entry-home'):
        // Import the module
        rout = 'home.js'

        break
  }
  
  const pageModule = await import('./routes/' + rout)
  const pageClass = new pageModule.default(page)
  pageClass.init()
}

export default router