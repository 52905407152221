import PhotoSwipe from "photoswipe";
import PhotoSwipeUI_Default from "photoswipe/dist/photoswipe-ui-default";

class Gallery {
  constructor(gallery) {
    this.gallery = gallery;
    this.items = this.gallery.querySelectorAll(".gallery-item");
  }

  init = () =>
    this.items.forEach((item) =>
      item.addEventListener("click", () => this.galleryInit(item))
    );

  galleryInit = (item) => {
    const images = JSON.parse(item.dataset.images);
    const gallery = new PhotoSwipe(
      document.querySelector(".pswp"),
      PhotoSwipeUI_Default,
      images,
      {
        index: 0,
        history: false,
        shareEl: false,
      }
    );
    gallery.listen("gettingData", function (index, item) {
      if (window.matchMedia("(min-width: 768px)").matches) {
        item.src = item.desktop.src;
        item.w = item.desktop.w;
        item.h = item.desktop.h;
        item.title = item.desktop.title;
      } else {
        item.src = item.mobile.src;
        item.w = item.mobile.w;
        item.h = item.mobile.h;
        item.title = item.mobile.title;
      }

      return item;
    });

    gallery.init();
  };
}

export default Gallery;
