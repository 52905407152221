import { gsap, ScrollTrigger } from 'gsap/all'
import { disablePageScroll, enablePageScroll } from 'scroll-lock'

gsap.registerPlugin(ScrollTrigger)

class MainMenu {
  constructor(menu){
    this.menu = document.querySelector(menu)
    this.menuWrapper = this.menu.parentElement
    this.button = this.menu.querySelector('.menu-button')
    this.nav = this.menu.querySelector('.main-menu')
    this.overlay = document.querySelector('.nav-overlay')
    this.open = false
    this.mobile = false
    this.sticky = false
  }

  init = () => {
    this.button.addEventListener('click', this.buttonHandler)
    this.resizeHandler()
    window.addEventListener('resize', this.resizeHandler)
    this.nav.addEventListener('touchmove', this.setMenuHeight)
    this.menuHide()
  }

  menuHide = () => {
    ScrollTrigger.create({
      trigger: document.body,
      start: '10px top',
      end: 99999,
      onUpdate: ({direction, isActive}) => {
        if(!this.open){
          if(direction == 1){
            this.menuWrapper.classList.add('-translate-y-full')
          }else{
            this.menuWrapper.classList.remove('-translate-y-full')
          }
          
          if (isActive) {
            this.menuWrapper.classList.add('bg-white')
          }else{
            this.menuWrapper.classList.remove('bg-white')
          }

          this.sticky = !this.sticky
        }
      }
    })
  }

  buttonHandler = () => {
    this.button.classList.toggle('open')
    this.overlay.classList.toggle('opacity-50')
    this.open = !this.open
    this.nav.classList.toggle('translate-x-full')

    if(this.open) disablePageScroll()
    else enablePageScroll()
  }

  close = () => {
    if(this.mobile){
      this.nav.classList.add('translate-x-full')
      this.button.classList.remove('open')
      this.overlay.classList.remove('opacity-50')
      this.open = false
      enablePageScroll()
    }
  }

  resizeHandler = () => {
    if(window.matchMedia('(min-width: 1280px)').matches && this.mobile === true){
      this.close()
      this.mobile = false
      gsap.set(this.nav, { height: '' })
    }else{
      this.setMenuHeight()
      this.mobile = true
    }
  }

  setMenuHeight = () => {
    if(this.mobile){
      gsap.set(this.nav, {
        height: window.innerHeight + 4
      })
    }
  }
}

export default MainMenu