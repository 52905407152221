import Swup from 'swup'
import SwupJsPlugin from '@swup/js-plugin'
import SwupGaPlugin from '@swup/ga-plugin'
import SwupGtmPlugin from '@swup/gtm-plugin'
import SwupScrollPlugin from '@swup/scroll-plugin'
import { gsap } from 'gsap/all'
import router from '../router'

gsap.defaults({
  duration: 0.3,
  ease: 'power2'
})
export default (menu) => {
  const preloader = document.querySelector('.preloader')
  const options = [
    {
      from: '(.*)', // meaning any
      to: '(.*)', // meaning any
      out: (next) => {
        menu.close()
        gsap.to(document.querySelector('.content'), 0.5, {
          opacity: 0,
          y: -160,
          onComplete: next
        })
        preloader.classList.remove('pointer-events-none', 'opacity-0')
      },
      in: (next) => {
        router()
        menu.nav = document.querySelector('.main-menu')
        gsap.set(document.querySelector('.content'), { y: 160 })
        gsap.to(document.querySelector('.content'), 0.5, {
          opacity: 1,
          y: 0,
          onComplete: next
        })
        preloader.classList.add('pointer-events-none', 'opacity-0')
      }
    }
  ]

  const swup = new Swup({
    plugins: [new SwupJsPlugin(options), new SwupGaPlugin(), new SwupGtmPlugin(), new SwupScrollPlugin({
      doScrollingRightAway: true
    })],
    containers: ['.content-inner', '.main-menu', '.footer'],
    requestHeaders: {
      "X-Requested-With": "XMLHttpRequest"
    },
    cache: false
  });
}