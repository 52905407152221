import axios from "axios";
class FormAjax {
  constructor(form) {
    this.form = form;
  }

  init = () => {
    if (this.form) {
      this.updateCsrf();
      if (!this.form.freeform) {
        this.form.freeform = new FreeFrom(this.form);
      }
      this.form.freeform.setOption("ajax", true);
      this.form.freeform.setOption("errorClassBanner", [
        "bg-tomato-red-15",
        "border",
        "border-tomato-red",
        "text-tomato-red",
        "px-6",
        "py-5",
        "relative",
        "mb-6",
        "error-banner",
      ]);
      this.form.freeform.setOption("errorClassList", [
        "errors",
        "text-tomato-red",
        "text-xs",
        "italic",
      ]);
      this.form.freeform.setOption("errorClassField", ["border-tomato-red"]);
      this.form.freeform.setOption("successClassBanner", [
        "bg-frog-green-15",
        "border",
        "border-frog-green",
        "text-frog-green",
        "px-6",
        "py-5",
        "relative",
        "mb-6",
      ]);
      // this.form.addEventListener('submit', this.ajaxifyForm, false)
      this.form.addEventListener(
        "freeform-stripe-styling",
        this.stripeStylingHandler
      );
    }
  };

  stripeStylingHandler = (event) => {
    event.detail.base = {
      fontSize: "16px",
      fontFamily: "din-2014, sans-serif",
    };
  };

  ajaxifyForm = async (event) => {
    const form = event.target;
    const data = new FormData(form);

    const method = form.getAttribute("method");
    const action = form.getAttribute("action");

    const responseObject = await axios({
      url: action ? action : window.location.href,
      method: method ? method : "post",
      data: data,
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        HTTP_X_REQUESTED_WITH: "XMLHttpRequest",
      },
    });

    const response = responseObject.data;

    if (response.success && response.finished) {
      form.reset();
    } else if (response.errors || response.formErrors) {
    }

    // Update the Honeypot field if using JS enhancement
    if (response.honeypot) {
      var honeypotInput = form.querySelector(
        "input[name^=freeform_form_handle_]"
      );

      if (honeypotInput) {
        honeypotInput.setAttribute("name", response.honeypot.name);
        honeypotInput.setAttribute("id", response.honeypot.name);
        honeypotInput.value = response.honeypot.hash;
      }
    }

    event.preventDefault();
  };

  updateCsrf = async () => {
    const formHandle = this.form.dataset.handle;
    const { data } = await axios.get(`/dynamic/freeform/${formHandle}`, {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        HTTP_X_REQUESTED_WITH: "XMLHttpRequest",
      },
    });

    this.form.querySelector("input[name=formHash]").value = data.hash;

    const honeypotInput = this.form.querySelector(
      `input[name^="${formHandle}"]`
    );
    if (honeypotInput) {
      const honeypot = data.honeypot;
      honeypotInput.setAttribute("id", honeypot.name);
      honeypotInput.setAttribute("name", honeypot.name);
      honeypotInput.value = honeypot.hash;
    }

    const csrf = data.csrf;
    const csrfInput = this.form.querySelector("input[name=" + csrf.name + "]");
    if (csrfInput) {
      csrfInput.value = csrf.value;
    }
  };

  destroy = () => {
    // this.form.removeEventListener('submit', this.ajaxifyForm)
    this.form.removeEventListener("freeform-ready", this.formReadyHandler);
    this.form.removeEventListener(
      "freeform-stripe-styling",
      this.stripeStylingHandler
    );
  };
}

export default FormAjax;
